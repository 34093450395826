import { ActionContext, ActionTree } from 'vuex'
import { AxiosResponse } from 'axios'
import { Account, Auth, Invoices, Pagination, Documents, GoodsIssuedNotes } from '@one/types'
import { mt } from './mutations'
import { AccountState, DocumentsPagePagination } from './types'
import { RootState } from '~/store'
import Agreement = Account.App.Responses.Agreement
import ClientType = Account.Backoffice.AgreementType.ClientType
import RegistrationSettings = Account.App.Responses.RegistrationSettings

import Pager = Pagination.Pager
import GoodsReceivedNoteRestView = GoodsIssuedNotes.Responses.GoodsReceivedNoteRestView;

export const actions: ActionTree<AccountState, RootState> = {
  createUserForClient(
    { dispatch }: ActionContext<AccountState, RootState>,
    payload: Account.App.Requests.CreateUserOption,
  ) {
    return this.$api.account.app
      .createUser(payload)
      .then(({ data }: AxiosResponse<void>) => {
        dispatch('fetchUsersList')
        return data
      })
  },
  updateUserForClient(
    { dispatch }: ActionContext<AccountState, RootState>,
    { idUser, dataUser },
  ) {
    return this.$api.account.app.updateUser(idUser, dataUser).then(() => {
      return dispatch('fetchUsersList')
    })
  },
  deleteUserForClient(
    { dispatch }: ActionContext<AccountState, RootState>,
    idUser: string,
  ) {
    return this.$api.account.app.deleteUser(idUser).then(() => {
      return dispatch('fetchUsersList')
    })
  },
  performUpdateCurrentUser(
    { dispatch }: ActionContext<AccountState, RootState>,
    payload: Account.App.Requests.EditUserOption,
  ) {
    return this.$api.account.app.updateCurrentUser(payload).then(() => {
      return dispatch('fetchGetCurrentUser')
    })
  },
  performUpdateCurrentUserPassword(
    _,
    payload: Account.App.Requests.Password,
  ) {
    return this.$api.account.app.updateCurrentPassword(payload)
  },
  userEditIsActive(
    { dispatch }: ActionContext<AccountState, RootState>,
    { idUser, dataIsActive },
  ) {
    return this.$api.account.app
      .editIsActiveUser(idUser, dataIsActive)
      .then(() => {
        return dispatch('fetchGetCurrentUser')
      })
  },
  fetchUsersList(
    { commit }: ActionContext<AccountState, RootState>,
    { pageSize = 99999, pageNumber = 1 } = {},
  ) {
    const data: Account.App.Requests.UsersListPager = {
      pageSize,
      pageNumber,
    }

    return this.$api.account.app
      .getUsersList(data)
      .then(
        (
          results: AxiosResponse<
            Pager<Account.App.Responses.User>
          >,
        ) => {
          commit('account/SET_USERS_LIST', results.data, { root: true })
          return Promise.resolve(results)
        },
      )
  },

  fetchCurrentUserAgreements({
    commit,
  }: ActionContext<AccountState, RootState>) {
    return this.$api.account.app
      .getAgreementsForCurrentUser()
      .then((results: AxiosResponse<Array<Agreement>>) => {
        commit(mt.SET_AGREEMENTS, results.data)
      })
  },

  fetchCurrentUserElectronicInvoicesAgreement({
    commit,
  }: ActionContext<AccountState, RootState>) {
    return this.$api.account.app
      .getElectronicInvoicesAgreementForCurrentUser()
      .then((results: AxiosResponse<Account.App.Responses.InvoiceAgreement>) => {
        commit(mt.SET_ELECTRONIC_INVOICES_AGREEMENT, results.data)
      })
  },

  fetchListAgreements(
    { commit }: ActionContext<AccountState, RootState>,
    clientType: ClientType = ClientType.INDIVIDUAL,
  ) {
    return this.$api.account.app
      .getListAgreements(clientType)
      .then((res: AxiosResponse<Array<Agreement>>) => {
        commit(mt.SET_AGREEMENTS, res.data)
        return Promise.resolve(res)
      })
  },

  fetchElectronicInvoicesAgreement({
    commit,
  }: ActionContext<AccountState, RootState>) {
    return this.$api.account.app
      .getElectronicInvoicesAgreement()
      .then(({ data }) => {
        commit(mt.SET_ELECTRONIC_INVOICES_AGREEMENT, data)
        return Promise.resolve(data)
      })
  },

  updateAgreements(
    { dispatch }: ActionContext<AccountState, RootState>,
    { agreementId, data },
  ) {
    return this.$api.account.app
      .updateAgreement(agreementId, data)
      .then(() => dispatch('fetchCurrentUserAgreements'))
  },

  updateElectronicInvoicesAgreement(
    { dispatch }: ActionContext<AccountState, RootState>,
    data: Account.App.Requests.EditInvoiceAgreement,
  ) {
    return this.$api.account.app
      .updateElectronicInvoicesAgreement(data)
      .then(() => dispatch('fetchCurrentUserElectronicInvoicesAgreement'))
  },

  createOptionalAgreement(
    _,
    { agreementId, agreement }: { agreementId: string; agreement: Account.App.Requests.AddOrDeleteAgreementForUnregisteredUserDto },
  ) {
    return this.$api.account.app
      .createOptionalAgreement(agreementId, agreement)
      .then(({ data }) => {
        return Promise.resolve(data)
      })
  },

  deleteOptionalAgreement(
    _,
    { agreementId, agreement }: { agreementId: string; agreement: Account.App.Requests.AddOrDeleteAgreementForUnregisteredUserDto },
  ) {
    return this.$api.account.app
      .deleteOptionalAgreement(agreementId, agreement)
      .then(({ data }) => {
        return Promise.resolve(data)
      })
  },

  fetchClientAddresses(
    { commit }: ActionContext<AccountState, RootState>,
    { rows = 99999, start = 0 } = {},
  ) {
    const data: Account.App.Requests.AddressesPager = {
      rows,
      start,
    }

    return this.$api.account.app
      .getClientAddresses(data)
      .then(
        (
          results: AxiosResponse<
            Pager<Account.App.Responses.Address>
          >,
        ) => {
          const response = results.data
          if (response.items) {
            const newObj = {
              items: response.items.sort(
                (
                  a: Account.App.Responses.Address,
                  b: Account.App.Responses.Address,
                ) => {
                  // do przegadania
                  if (b.type === 'BILLING') {
                    return 1
                  }
                  if (a.type === 'BILLING') {
                    return -1
                  }
                  if (b.isDefault) {
                    return 1
                  }
                  if (a.isDefault) {
                    return -1
                  }
                  return 0
                },
              ),
              totalItems: response.totalItems,
            }
            commit(mt.SET_CLIENT_ADDRESSES, newObj)
          }
        },
      )
  },
  setDefaultAddress(
    _,
    idAddress: string,
  ) {
    return this.$api.account.app.setDefaultAddress(idAddress)
  },
  deleteAddress(
    _,
    idAddress: string,
  ) {
    return this.$api.account.app.deleteAddress(idAddress)
  },
  createAddress(_, { address }) {
    return this.$api.account.app.createAddress(address)
  },
  fetchTradeCreditByClient({
    commit,
  }: ActionContext<AccountState, RootState>) {
    return this.$api.account.app
    .getTradeCreditByClient()
    .then((results: AxiosResponse<Account.App.Responses.TradeCredit>) => {
      commit(mt.SET_TRADE_CREDIT, results.data)
    })
  },
  fetchTradeCreditForPayer({
    commit,
  }: ActionContext<AccountState, RootState>) {
    return this.$api.account.app
    .getTradeCreditForPayer()
    .then((results: AxiosResponse<Account.App.Responses.TradeCredit>) => {
      commit(mt.SET_TRADE_CREDIT, results.data)
    })
  },
  createOrganization(
    _,
    payload: Account.App.Requests.CreateOrganization,
  ) {
    return this.$api.account.app
      .createOrganization(payload)
      .then((results: AxiosResponse<void>) => {
        return results.data
      })
  },
  createIndividualClient(
    _,
    payload: Account.App.Requests.CreateIndividualClient,
  ) {
    return this.$api.account.app
      .createIndividualUser(payload)
  },
  fetchClient({
    commit,
    dispatch,
  }: ActionContext<AccountState, RootState>) {
    return this.$api.account.app
      .getClient()
      .then((results: AxiosResponse<Account.App.Responses.ClientRestView>) => {
        commit(mt.SET_CLIENT_DATA, results.data)
        if (
          results.data.warehouseId &&
          results.data.warehouseId.trim() !== ''
        ) {
          return dispatch(
            'stocks/setDefaultWarehouseIfNotSelected',
            results.data.warehouseId,
            {
              root: true,
            },
          )
        }
        return Promise.resolve()
      })
  },
  fetchSingleDocument(
    { commit }: ActionContext<AccountState, RootState>,
    documentNumber: string,
  ) {
    return this.$api.invoices.app
      .getSingleDocument(documentNumber)
      .then((results: AxiosResponse<Documents.DocumentDto>) => {
        commit(mt.SET_DOCUMENTS, [results.data])
        return Promise.resolve(results.data)
      })
  },
  fetchDocumentsPaymentData({
    commit,
    state,
  }: ActionContext<AccountState, RootState>) {
    const {
      selectedDocuments,
      documentTypes,
    }: DocumentsPagePagination = state.documentsPage
    if (selectedDocuments.length > 0) {
      return this.$api.invoices.app
        .getDocumentsPaymentData(selectedDocuments, documentTypes)
        .then((results: AxiosResponse<Documents.DocumentSums>) => {
          commit(mt.SET_DOCUMENTS_PAYMENT_DATA, results.data)
          return Promise.resolve(results.data)
        })
    } else {
      commit(mt.SET_DOCUMENTS_PAYMENT_DATA, null)
    }
  },
  fetchDocumentsList({
    state,
    commit,
  }: ActionContext<AccountState, RootState>) {
    const {
      page,
      pageSize,
      search,
      startDate,
      endDate,
      paymentFilter,
      sortingMode,
      documentTypes,
    }: any = state.documentsPage
    commit('FETCHING')

    return this.$api.invoices.app
      .getDocumentsList(
        page,
        pageSize,
        search,
        startDate,
        endDate,
        paymentFilter,
        sortingMode,
        documentTypes,
      )
      .then((results: any) => {
        const response: Pagination.Pager<Documents.DocumentDto> = results.data
        const numbers = response.items.map((p: any) => p.number)
        commit(mt.SET_PAGINATED_DOCUMENTS, numbers)
        commit(mt.SET_DOCUMENTS, response.items)
        commit(mt.SET_TOTAL, response.totalItems)
        commit('FETCHED')
        return Promise.resolve(response)
      })
  },
  fetchPaginatedDocuments({
    state,
    dispatch,
  }: ActionContext<AccountState, RootState>) {
    const {
      page,
      loadedPages,
    }: DocumentsPagePagination = state.documentsPage
    if (!(page in loadedPages)) {
      return dispatch('fetchDocumentsList')
    }
    return Promise.resolve()
  },
  searchChanged(
    { commit, dispatch }: ActionContext<AccountState, RootState>,
    search: string | null,
  ) {
    commit(mt.SEARCH_CHANGED, search)
    commit(mt.SET_PAGE, 1)
    commit(mt.CLEAR_LOADED)
    commit(mt.SET_ALL_NUMBERS_DOCUMENTS, [])
    commit(mt.SET_DOCUMENTS_PAYMENT_DATA, null)
    commit(mt.SET_CHANGE_DOCUMENT_FILTER, 'ALL')
    commit(mt.SET_SORTING_MODE, 'OLDEST_TO_NEWEST_BY_PAYMENT_DATE')
    return dispatch('fetchPaginatedDocuments')
  },
  changePage(
    { commit, dispatch }: ActionContext<AccountState, RootState>,
    page: number,
  ) {
    commit(mt.SET_PAGE, page)
    return dispatch('fetchPaginatedDocuments')
  },
  /**
   * Fetch get current user
   */
  fetchGetCurrentUser({
    commit,
  }: ActionContext<AccountState, RootState>) {
    return this.$api.account.app
      .getCurrentUser()
      .then((results: AxiosResponse<Account.App.Responses.User>) => {
        commit(mt.SET_CURRENT_USER, results.data)
      })
  },
  impersonate(
    _,
    email: string,
  ) {
    return this.$api.authserver.app
      .impersonate(email)
      .then(async (results: AxiosResponse<Auth.Responses.Token>) => {
        const auth: Auth.Responses.Token = results.data

        const authService = this.$auth || this._vm.$auth
        // @ts-ignore
        await authService.authorize(auth)
        return auth
      })
      .catch((e: any) => {
        return Promise.reject(e)
      })
  },
  /**
   * Roles and Authorities
   */
  getAuthorities({ commit }: ActionContext<AccountState, RootState>) {
    return this.$api.authserver.app
      .authorities()
      .then((results: AxiosResponse<Auth.Roles.Authorities>) => {
        commit(mt.SET_AUTHORITIES, results.data)
      })
  },

  getRoles({ commit }: ActionContext<AccountState, RootState>) {
    return this.$api.authserver.app
      .fetchRoles()
      .then((results: AxiosResponse<Array<Auth.Roles.Responses.Role>>) => {
        commit(mt.SET_ROLES, results.data)
        return results.data
      })
  },

  createRole(
    { dispatch }: ActionContext<AccountState, RootState>,
    data: Auth.Roles.Requests.CreateEditRoleOption,
  ) {
    return this.$api.authserver.app.createRole(data).then(() => {
      return dispatch('getRoles')
    })
  },

  getRoleByKey(
    _,
    roleId: string,
  ) {
    return this.$api.authserver.app
      .roleById(roleId)
      .then((results: AxiosResponse<Auth.Roles.Responses.Role>) => {
        return results.data
      })
  },

  updateRole(
    { dispatch }: ActionContext<AccountState, RootState>,
    payload: Auth.Roles.Requests.UpdateRoleOption,
  ) {
    return this.$api.authserver.app.updateRole(payload).then(() => {
      return dispatch('getRoles')
    })
  },

  deleteRole(
    { dispatch }: ActionContext<AccountState, RootState>,
    roleId: string,
  ) {
    return this.$api.authserver.app.deleteRole(roleId).then(() => {
      return dispatch('getRoles')
    })
  },

  getWarehouseStatus({ commit }: ActionContext<AccountState, RootState>) {
    return this.$api.account.app.getAccountWarehouseSetting().then((res) => {
      commit(mt.SET_WAREHOUSE_BLOCKED, res.data)
      return res.data
    })
  },

  getRegistrationSetting({
    commit,
  }: ActionContext<AccountState, RootState>) {
    return this.$api.account.app
      .getAccountRegistrationSetting()
      .then(({ data }: { data: RegistrationSettings }) => {
        commit(mt.SET_REGISTRATION_SETTING, data)
        return data
      })
  },

  getGoodsIssueNotes({
    state,
    commit,
  }: ActionContext<AccountState, RootState>) {
    const {
      pageNumber,
      pageSize,
      search,
      startDate,
      endDate,
      sortOrder,
    }: Invoices.GoodsIssuedPagePagination = state.goodsIssuedNotesPage

    return this.$api.goodsIssuedNotes.app
      .getGoodsIssuedNotes(
        pageNumber,
        pageSize,
        search,
        startDate,
        endDate,
        sortOrder,
      )
      .then(({ data }: AxiosResponse<any>) => {
        commit(mt.SET_GOODS_ISSUED_NOTES, data)
        return Promise.resolve(data)
      })
  },

  getGoodsIssuedNote(
    { commit }: ActionContext<AccountState, RootState>,
    goodsIssueNoteNumber,
  ) {
    return this.$api.goodsIssuedNotes.app
      .getGoodsIssuedNote(goodsIssueNoteNumber)
      .then(({ data }: AxiosResponse<any>) => {
        commit(mt.SET_GOODS_ISSUED_NOTE, data)
        return Promise.resolve(data)
      })
  },

  getGusData(
    { commit }: ActionContext<AccountState, RootState>,
    tin: string,
  ) {
    return this.$api.account.app
      .getTaxpayerAddress(tin)
      .then(({ data }: AxiosResponse<Account.App.Responses.BillingAddress>) => {
        commit(mt.SET_GUS_DATA, data)
        return Promise.resolve(data)
      })
  },

  getGoodsReceivedNote(
    { commit }: ActionContext<AccountState, RootState>,
    goodsReceivedNoteNumber,
  ) {
    return this.$api.goodsIssuedNotes.app
      .getGoodsReceivedNote(goodsReceivedNoteNumber)
      .then(({ data }: AxiosResponse<GoodsReceivedNoteRestView>) => {
        commit(mt.SET_GOODS_RECEIVED_NOTE, data)
        return Promise.resolve(data)
      })
  },

  getGoodsReceivedNotes({
   state,
   commit,
 }: ActionContext<AccountState, RootState>) {
    const {
      pageNumber,
      pageSize,
      search,
      startDate,
      endDate,
      sortOrder,
    }: GoodsIssuedNotes.Backoffice.Responses.GoodsIssuedPagePagination = state.goodsReceivedNotesPage

    return this.$api.goodsIssuedNotes.app
      .getGoodsReceivedNotes(
        pageNumber,
        pageSize,
        search,
        startDate,
        endDate,
        sortOrder,
      )
      .then(({ data }: AxiosResponse<any>) => {
        commit(mt.SET_GOODS_RECEIVED_NOTES, data)
        return Promise.resolve(data)
      })
  },
}
export default actions
